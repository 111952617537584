import React from 'react';
import { Link } from 'react-router-dom';

const Home = (props) => {
    return <div>
        <div className="container-fluid text-center py-5" style={{ marginTop: "130px" }}>
            <h1 style={{ fontSize: "5.5rem" }}>Welcome to NewsKet</h1>
            <p style={{ fontSize: "3.25rem" }} className="lead">Your Personalized News Site</p>
            <Link to="/general" className="btn btn-light btn-lg">Explore Now</Link>
        </div>

        <div className="container my-5">
            <div className="row text-center mt-4">
                <div className="col-12">
                    <p>Follow me on:</p>
                    <a href="https://github.com/KetanSharma91" className="btn btn-outline-info mx-2" style={{ cursor: 'pointer' }}>
                        <i class="bi bi-github"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/ketan-sharma-b1b0a5288/" className="btn btn-outline-info mx-2" style={{ cursor: 'pointer' }}>
                        <i class="bi bi-linkedin"></i>
                    </a>
                    <a href="https://x.com/KetanSharm37993" className="btn btn-outline-info mx-2" style={{ cursor: 'pointer' }}>
                        <i className="bi bi-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/ketansharma911/" className="btn btn-outline-info mx-2" style={{ cursor: 'pointer' }}>
                        <i className="bi bi-instagram"></i>
                    </a>
                </div>
            </div>
        </div>

        <footer className="bg-light py-4 text-center bg-info-subtle text-info-emphasis">
            <p style={{ fontSize: '1.5rem' }}>&copy; 2024 <Link to="https://ketan.rf.gd/" target="_blank" rel="noopener noreferrer">Ketan Sharma</Link> News. All rights reserved.</p>
        </footer>

    </div>;
}

export default Home;