import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NewsItem from './NewsItem';
import Spinner from './Spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

const News = (props) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);

    console.log(loading,totalResults);

    // Fetch news data
    const updateNews = async () => {
        try {
            props.setProgress(10);
            setLoading(true);
            const url = `https://api.thenewsapi.com/v1/news/top?api_token=${props.apiKey}&locale=${props.country}&categories=${props.category}&limit=${props.pagesize}&page=${page}`;
            const data = await fetch(url);
            props.setProgress(30);
            const parsedData = await data.json();
            props.setProgress(70);

            // Ensure correct handling of data structure
            setArticles(parsedData.articles || parsedData.data || []);
            setTotalResults(parsedData.totalResults || parsedData.meta?.total || 0);
            setLoading(false);
            props.setProgress(100);
        } catch (error) {
            console.error('Error fetching news:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = `${props.category.charAt(0).toUpperCase() + props.category.slice(1).toLowerCase()} - NewsKet`;
        updateNews();
        // eslint-disable-next-line
    }, []);

    // Fetch more data for InfiniteScroll
    const fetchMoreData = async () => {
        try {
            const nextPage = page + 1;
            const url = `https://api.thenewsapi.com/v1/news/top?api_token=${props.apiKey}&locale=${props.country}&categories=${props.category}&limit=${props.pagesize}&page=${nextPage}`;
            const data = await fetch(url);
            const parsedData = await data.json();

            // Append new articles and update page
            setArticles((prevArticles) => prevArticles.concat(parsedData.articles || parsedData.data || []));
            setTotalResults(parsedData.totalResults || parsedData.meta?.total || 0);
            setPage(nextPage);
        } catch (error) {
            console.error('Error fetching more news:', error);
        }
    };

    return (
        <>
            <h1 className="text-center" style={{ margin: '35px 0', marginTop: '90px' }}>
                NewsKet - Top Headlines from{' '}
                {props.category.charAt(0).toUpperCase() + props.category.slice(1).toLowerCase()}
            </h1>

            <InfiniteScroll 
                dataLength={articles.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<Spinner />}
            >
                <div className="container">
                    <div className="row my-3">
                        {articles.map((element) => (
                            <div className="col-md-4" key={element.url}>
                                <NewsItem
                                    title={element.title || ''}
                                    desc={element.description ? element.description.slice(0, 88) : ''}
                                    imgurl={element.image_url }
                                    newsurl={element.url}
                                    author={element.author}
                                    date={element.published_at}
                                    source={element.source}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </InfiniteScroll>
        </>
    );
};

News.propTypes = {
    country: PropTypes.string,
    pagesize: PropTypes.number,
    category: PropTypes.string,
    setProgress: PropTypes.func.isRequired,
};

News.defaultProps = {
    country: 'us',
    pagesize: 8,
    category: 'general'
};

export default News;

