import React from 'react';

const NewsItem = (props) => {

        const { title, desc, imgurl, newsurl, author, date, source } = props;
        
        return <div>
            <div className="card my-3">
                <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '0'}}>
                    <span className="badge rounded-pill bg-danger">{source}</span>
                </div>

                <img src={imgurl ? imgurl : 'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{desc}...</p>
                    <p className="card-text"><small className="text-muted">By {!author ? "Unknown" : author} on {new Date(date).toGMTString()} </small></p>
                    <a href={newsurl} className="btn btn-primary btn-dark" target="_blank" rel="noopener noreferrer">Read More</a>
                </div>
            </div>
        </div>;
}

export default NewsItem;