import './App.css';
import React, { useState } from 'react';
import NavBar from './components/NavBar';
import News from './components/News';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'
import About from './components/About';
import Home from './components/Home';

function App() {

  const pages = 6;
  // const apiKey = 'ef0f7b863e27460ba548761fea106897';
  const apiKey = 'GqFuvJIscheuMEoG5mCxbQV5brbXQIWPoOgc6npA';
  // apiKey = process.env.REACT_NEWS_APP_API;

  const [nation, setNation] = useState('in');

  const [progress, setProgress] = useState(0);

  return (
    <div>
      <Router>
        <NavBar setNation={setNation} />
        <LoadingBar
          color='#f11946'
          progress={progress}
        />
        <Routes>
        <Route path="/" element={<Home setProgress={setProgress} apiKey={apiKey} />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/us" element={<News setProgress={setProgress} nation='us' apiKey={apiKey} key='general' pagesize={pages} country={nation} category='general' />} />
          <Route path="/in" element={<News setProgress={setProgress} nation='in' apiKey={apiKey} key='general' pagesize={pages} country={nation} category='general' />} />
          <Route path="/" element={<News setProgress={setProgress} apiKey={apiKey} key='general' pagesize={pages} country={nation} category='general' />} />
          <Route path="/business" element={<News setProgress={setProgress} apiKey={apiKey} key='business' pagesize={pages} country={nation} category='business' />} />
          <Route path="/entertainment" element={<News setProgress={setProgress} apiKey={apiKey} key='entertainment' pagesize={pages} country={nation} category='entertainment' />} />
          <Route path="/general" element={<News setProgress={setProgress} apiKey={apiKey} key='general' pagesize={pages} country={nation} category='general' />} />
          <Route path="/health" element={<News setProgress={setProgress} apiKey={apiKey} key='health' pagesize={pages} country={nation} category='health' />} />
          <Route path="/science" element={<News setProgress={setProgress} apiKey={apiKey} key='science' pagesize={pages} country={nation} category='science' />} />
          <Route path="/sports" element={<News setProgress={setProgress} apiKey={apiKey} key='sports' pagesize={pages} country={nation} category='sports' />} />
          <Route path="/technology" element={<News setProgress={setProgress} apiKey={apiKey} key='technology' pagesize={pages} country={nation} category='tech' />} />
          <Route path="/food" element={<News setProgress={setProgress} apiKey={apiKey} key='food' pagesize={pages} country={nation} category='food' />} />
          <Route path="/travel" element={<News setProgress={setProgress} apiKey={apiKey} key='travel' pagesize={pages} country={nation} category='travel' />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App